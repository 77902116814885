// version: 3.5.1 // 27.05.2024 // customize for keycloak

import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import axios, {
  AxiosError, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import qs from 'qs';
import { capitalizeFirstLetter } from '../utils';
import { AnyObject } from '../types';

export const FILE_FORMAT: { [key: string]: string } = {
  aac: 'audio/aac',
  abw: 'application/x-abiword',
  arc: 'application/x-freearc',
  avif: 'image/avif',
  avi: 'video/x-msvideo',
  azw: 'application/vnd.amazon.ebook',
  bin: 'application/octet-stream',
  bmp: 'image/bmp',
  bz: 'application/x-bzip',
  bz2: 'application/x-bzip2',
  cda: 'application/x-cdf',
  csh: 'application/x-csh',
  css: 'text/css',
  csv: 'text/csv',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  eot: 'application/vnd.ms-fontobject',
  epub: 'application/epub+zip',
  gz: 'application/gzip',
  gif: 'image/gif',
  htm: 'text/html',
  html: 'text/html',
  ico: 'image/vnd.microsoft.icon',
  ics: 'text/calendar',
  jar: 'application/java-archive',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  js: 'text/javascript',
  json: 'application/json',
  jsonld: 'application/ld+json',
  mid: 'audio/midi',
  midi: 'audio/x-midi',
  mjs: 'text/javascript',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  mpeg: 'video/mpeg',
  mpkg: 'application/vnd.apple.installer+xml',
  odp: 'application/vnd.oasis.opendocument.presentation',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odt: 'application/vnd.oasis.opendocument.text',
  oga: 'audio/ogg',
  ogv: 'video/ogg',
  ogx: 'application/ogg',
  opus: 'audio/opus',
  otf: 'font/otf',
  png: 'image/png',
  pdf: 'application/pdf',
  php: 'application/x-httpd-php',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  rar: 'application/vnd.rar',
  rtf: 'application/rtf',
  sh: 'application/x-sh',
  svg: 'image/svg+xml',
  tar: 'application/x-tar',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  ts: 'video/mp2t',
  ttf: 'font/ttf',
  txt: 'text/plain',
  vsd: 'application/vnd.visio',
  wav: 'audio/wav',
  weba: 'audio/webm',
  webm: 'video/webm',
  webp: 'image/webp',
  woff: 'font/woff',
  woff2: 'font/woff2',
  xhtml: 'application/xhtml+xml',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xml: 'application/xml',
  xul: 'application/vnd.mozilla.xul+xml',
  zip: 'application/zip',
  '3gp': 'video/3gpp; audio/3gpp',
  '3g2': 'video/3gpp2; audio/3gpp2',
  '7z': 'application/x-7z-compressed',
};

export interface RequestResult<Error = DefaultFetchError> {
  loading: boolean;
  error: AxiosError<Error> | null;
}

export interface FetchSuccess {
  success: boolean;
}

export interface PagingParams extends AnyObject {
  page?: number;
  pageSize?: number;
  orderBy?: string;
}

export interface PagingDataResponse<I> {
  data: I[];
  meta: {
    page: number;
    take: number;
    itemCount: number;
    pageCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}

export interface FetchProps<Data, Props = undefined, DecorateData = Data> {
  fetchCreator: (
    setController: (controller: AbortController) => void,
    token?: string,
    props?: Props,
    ...args: unknown[]
  ) => Promise<AxiosResponse<Data>>;

  /**
   * Automatically cancels any previous fetch if it's still running.
   */
  latest?: boolean;
  decorateData?: (data: Data) => DecorateData;
  startStateLoading?: boolean;
  multiple?: string;
  cacheLifetime?: number;
  authorization?: boolean;
}

export type DefaultFetchError = {
  message?: string | string[]
  error?: string
};

export interface DefaultFetch<Data = undefined, Error = DefaultFetchError, Props = undefined, DecorateData = Data>
  extends RequestResult<Error> {
  abort: () => void;
  controller?: AbortController;
  history?: Props;
  fetch: (props?: Props) => Promise<DecorateData | null>;
  finish: (data?: DecorateData) => void;
  response: AxiosResponse<Data> | undefined;
  clearError: () => void;
  clearResponse: () => void;
  name?: string;
  onFinish: (callback: (data: FetchData<Data, Error, Props, DecorateData>) => void) => void;
}

export interface FetchData<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>
  extends RequestResult<Error> {
  data?: DecorateData;
  response: AxiosResponse<Data> | undefined;
}

export interface FetchHooks<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>
  extends DefaultFetch<Data, Error, Props, DecorateData> {
  data?: DecorateData;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const requestQueue: { [key: string]: Promise<any>; } = {};

interface Cache<Data> {
  cacheLifetime: number; // milliseconds
  response: AxiosResponse<Data> | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cache: { [key: string]: Cache<any>; } = {};

export function useFetch<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>({
  fetchCreator,
  latest = false,
  decorateData,
  startStateLoading = false,
  multiple, // string key
  cacheLifetime = 200, // milliseconds
}: FetchProps<Data, Props, DecorateData>): FetchHooks<Data, Error, Props, DecorateData> {
  const { keycloak, initialized } = useKeycloak();
  const accessToken = initialized ? keycloak?.token : '';
  const onFinishSubscriber = useRef<((data: FetchData<Data, Error, Props, DecorateData>) => void)[]>([]);
  const history = useRef<Props>();
  const controller = useRef<AbortController | undefined>();
  const live = useRef<boolean>(true);
  const [loading, setLoading] = useState(startStateLoading);
  const [error, setError] = useState<AxiosError<Error> | null>(null);
  const [data, setData] = useState<DecorateData>();
  const [response, setResponse] = useState<AxiosResponse<Data> | undefined>();

  useEffect(() => {
    if (onFinishSubscriber.current.length > 0 && (response || error)) {
      onFinishSubscriber.current.forEach((callback) => {
        try {
          callback({
            loading,
            error,
            data,
            response,
          });
        } catch (error_) {
          console.warn(error_);
        }
      });

      onFinishSubscriber.current = [];
    }
  }, [response, error]);

  useEffect(() => {
    if (response && cacheLifetime && multiple && (!cache[multiple] || cache[multiple].response !== response)) {
      cache[multiple] = {
        cacheLifetime: Date.now() + cacheLifetime,
        response,
      };

      setTimeout(() => {
        if (typeof cache[multiple] !== 'undefined') {
          delete cache[multiple];
        }
      }, cacheLifetime);
    }
  }, [response]);

  const fetch = useCallback(async (params?: Props, ...args: unknown[]): Promise<DecorateData | null> => {
    const cacheResponse = (!params || Object.values(params).length === 0)
    && multiple && cache[multiple] ? cache[multiple].response : undefined;

    setError(() => null);
    setLoading(() => true);

    if (latest && controller.current) {
      controller.current.abort();
    }

    const checkResponse = async (useReLogin = false, token = accessToken): Promise<DecorateData | null> => {
      let promise = useReLogin && !latest && multiple ? requestQueue[multiple] : undefined;

      const prepareData = (res: AxiosResponse<Data>): DecorateData | null => {
        const result = decorateData ? decorateData(res.data) : res.data;

        setData(() => result as DecorateData);
        setResponse(() => res);
        setLoading(() => false);

        return result as DecorateData;
      };

      if (!promise) {
        if (!latest && cacheResponse) {
          return prepareData(cacheResponse);
        }

        if (latest && controller.current) {
          controller.current.abort();
        }

        promise = fetchCreator(
          (abortController) => {
            controller.current = abortController;
          },
          token || '',
          params,
          ...args,
          cacheResponse,
        );

        history.current = params;

        if (!latest && multiple) {
          requestQueue[multiple] = promise;
        }
      }

      // await new Promise((resolve) => {
      //   setTimeout(resolve, 5000);
      // });

      return await promise.then((res) => {
        if (!live.current) {
          return null as DecorateData;
        }

        return prepareData(res);
      }).catch(async (error_) => {
        if (!axios.isCancel(error_)) {
          if (!live.current) {
            return null;
          }

          setError(() => error_);
          setLoading(() => false);
        }

        return error_;
      }).finally(() => {
        if (!latest && multiple) {
          delete requestQueue[multiple];
        }
      });
    };

    return checkResponse();
  }, [accessToken]);

  useEffect(() => () => {
    live.current = false;
  }, []);

  return {
    abort: () => {
      if (controller.current) {
        controller.current.abort();
      }
    },
    controller: controller.current,
    history: history.current,
    loading,
    error,
    data,
    fetch,
    response,
    onFinish: (callback: (data: FetchData<Data, Error, Props, DecorateData>) => void) => {
      if (typeof callback === 'function') {
        onFinishSubscriber.current.push(callback);
      }
    },
    finish: (result) => {
      setData(() => result);
      setLoading(() => false);
      setError(() => null);
    },
    clearError: () => setError(() => null),
    clearResponse: () => {
      // @ts-ignore @typescript-eslint/no-empty-function
      setData(() => {});
      // @ts-ignore @typescript-eslint/no-empty-function
      setResponse(() => {});
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FetchGet<Data = any, Props = any, Error = DefaultFetchError, DecorateData = Data>
  extends DefaultFetch<Data, Error, Props, DecorateData> {
  data?: DecorateData;
}

export interface FetchOptions<Data, Props, DecorateData = Data>
  extends Omit<FetchProps<Data, Props, DecorateData>, 'fetchCreator'> {
  name?: string; // name fetch function
  url?: string;
  authorization?: boolean;
  decorateData?: (data: Data) => DecorateData;
  config?: AxiosRequestConfig;
  params?: Props;
  autoStart?: boolean;
  multiple?: string;
  cacheLifetime?: number;
  startStateLoading?: boolean;
}

export type FetchGetOptions<Data, Props, DecorateData = Data> = FetchOptions<Data, Props, DecorateData>;

export function useFetchGet<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>(
  path: string,
  options: FetchGetOptions<Data, Props, DecorateData> = {},
): FetchGet<Data, Props, Error, DecorateData> {
  const {
    name,
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    autoStart = true,
    authorization = true,
    startStateLoading = true,
    ...props
  } = options || {};

  const { fetch, ...args } = useFetch<Data, Error, Props, DecorateData>({
    fetchCreator: (setController, token, paramsCreator?: Props) => {
      const controller = new AbortController();

      setController(controller);

      return axios.get<Data>(
        url || `${process.env.REACT_APP_API}${path}`,
        {
          signal: controller.signal,
          ...config,
          headers: {
            Authorization: authorization ? `Bearer ${token}` : undefined,
            ...config?.headers,
          },
          params: {
            ...config?.params,
            ...params,
            ...paramsCreator,
          },
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
        },
      );
    },
    authorization,
    decorateData,
    startStateLoading,
    ...props,
  });

  useEffect(() => {
    if (autoStart) {
      fetch();
    }
  }, []);

  return {
    ...args,
    name,
    fetch,
  };
}

export interface FetchGetId<Data = AnyObject, Error = DefaultFetchError, Props = undefined, DecorateData = Data>
  extends DefaultFetch<Data, Error, Props, DecorateData> {
  data?: DecorateData;
  fetch: (params?: Props, id?: string | number) => Promise<DecorateData | null>;
}

export type FetchGetIdOptions<Data, Props, DecorateData = Data> = FetchOptions<Data, Props, DecorateData>;

export function useFetchGetId<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>(
  path: string,
  initialId = '',
  options: FetchGetIdOptions<Data, Props, DecorateData> = {},
  responseType: 'arraybuffer' | 'json' | 'blob' | 'text' | 'stream' | 'document' = 'json',
  axiosOnDownloadProgress: (progressEvent: AxiosProgressEvent) => void = () => {},
): FetchGetId<Data, Error, Props, DecorateData> {
  const {
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    autoStart = true,
    authorization = true,
    startStateLoading = false,
    ...props
  } = options || {};

  const { fetch, ...args } = useFetch<Data, Error, Props, DecorateData>({
    fetchCreator: (setController, token, paramsCreator?: Props, id = initialId) => {
      const controller = new AbortController();

      setController(controller);

      return axios.get<Data>(
        url || `${process.env.REACT_APP_API}${path}${id ? `/${id}` : ''}`,
        {
          signal: controller.signal,
          ...config,
          headers: {
            Authorization: authorization ? `Bearer ${token}` : undefined,
            ...config?.headers,
          },
          params: {
            ...config?.params,
            ...params,
            ...paramsCreator,
          },
          responseType,
          onDownloadProgress: axiosOnDownloadProgress
            ? (progressEvent) => axiosOnDownloadProgress(progressEvent) : undefined,
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
        },
      );
    },
    authorization,
    decorateData,
    startStateLoading,
    ...props,
  });

  useEffect(() => {
    if (autoStart) {
      fetch();
    }
  }, []);

  return {
    ...args,
    fetch,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FetchCreate<Data = FetchSuccess, Error = DefaultFetchError, Props = any>
  extends DefaultFetch<Data, Error, Props> {
  data?: Data;
  fetch: (formData?: Props, id?: string) => Promise<Data | null>;
}

export type FetchCreateOptions<Data, Props> = FetchOptions<Data, Props>;

export function useFetchCreate<Data, Error, Props>(
  path: string,
  options: FetchCreateOptions<Data, Props> = {},
  axiosOnUploadProgress: (progressEvent: AxiosProgressEvent) => void = () => {},
): FetchCreate<Data, Error, Props> {
  const {
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    authorization = true,
    startStateLoading = false,
    ...props
  } = options || {};

  return useFetch<Data, Error, Props>({
    fetchCreator: (setController, token, formData?: Props, partUrl = '') => {
      const controller = new AbortController();

      setController(controller);

      return axios.post<Data>(
        url || `${process.env.REACT_APP_API}${path}${partUrl ? `/${partUrl}` : ''}`,
        formData,
        {
          signal: controller.signal,
          ...config,
          headers: {
            Authorization: authorization ? `Bearer ${token}` : undefined,
            ...config?.headers,
          },
          params: {
            ...config?.params,
            ...params,
          },
          onUploadProgress: axiosOnUploadProgress
            ? (progressEvent) => axiosOnUploadProgress(progressEvent) : undefined,
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
        },
      );
    },
    authorization,
    decorateData,
    startStateLoading,
    ...props,
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FetchUpdate<Data = FetchSuccess, Error = DefaultFetchError, Props = any>
  extends DefaultFetch<Data, Error, Props> {
  data?: Data;
  fetch: (params?: Props, id?: string | number) => Promise<Data | null>;
}

export type FetchUpdateOptions<Data, Props> = FetchOptions<Data, Props>;

export function useFetchUpdate<Data, Error, Props>(
  path: string,
  initialId = '',
  options: FetchCreateOptions<Data, Props> = {},
  axiosOnUploadProgress: (progressEvent: AxiosProgressEvent) => void = () => {},
): FetchUpdate<Data, Error, Props> {
  const {
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    authorization = true,
    startStateLoading = false,
    ...props
  } = options || {};

  return useFetch<Data, Error, Props>({
    fetchCreator: (setController, token, formData?: Props, id = initialId) => {
      const controller = new AbortController();

      setController(controller);

      return axios.patch<Data>(
        url || `${process.env.REACT_APP_API}${path}${id ? `/${id}` : ''}`,
        formData,
        {
          signal: controller.signal,
          ...config,
          headers: {
            Authorization: authorization ? `Bearer ${token}` : undefined,
            ...config?.headers,
          },
          params: {
            ...config?.params,
            ...params,
          },
          onUploadProgress: axiosOnUploadProgress
            ? (progressEvent) => axiosOnUploadProgress(progressEvent) : undefined,
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
        },
      );
    },
    authorization,
    decorateData,
    startStateLoading,
    ...props,
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FetchDelete<Data = any, Error = DefaultFetchError, Props = string>
  extends DefaultFetch<Data, Error, Props> {
  data?: Data;
  fetch: (id?: Props) => Promise<Data | null>;
}

export function useFetchDelete<Data, Error, Props = string>(
  path: string,
  initialId = '',
  options: FetchCreateOptions<Data, Props> = {},
): FetchDelete<Data, Error, Props> {
  const {
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    authorization = true,
    startStateLoading = false,
    ...props
  } = options || {};

  return useFetch<Data, Error, Props>({
    fetchCreator: (setController, token, id) => {
      const controller = new AbortController();

      setController(controller);

      return axios.delete<Data>(
        url || `${process.env.REACT_APP_API}${path}${id || initialId ? `/${id || initialId}` : ''}`,
        {
          signal: controller.signal,
          ...config,
          headers: {
            Authorization: authorization ? `Bearer ${token}` : undefined,
            ...config?.headers,
          },
          params: {
            ...config?.params,
            ...params,
          },
          paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
        },
      );
    },
    authorization,
    decorateData,
    startStateLoading,
    ...props,
  });
}

export const listAliasError: { [key: string]: string } = {
  'error.unique.email': 'A user with this email already exists',
  unauthorized: 'Incorrect email or password',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMessageInError(err: any, list = listAliasError): string {
  if (!err) {
    return 'Unknown error';
  }

  const message = err?.data?.message
    || err.response?.data?.detail
    || err.response?.data?.message
    || err.response?.data?.error
    || err.message;

  let result = 'Something went wrong!';

  if (message) {
    result = capitalizeFirstLetter(Array.isArray(message) ? message[0] : message);
  }

  return list[result.toLowerCase()] || result;
}

export interface SendAllFetch<Props> {
  loading: boolean
  error: AxiosError<DefaultFetchError> | null
  list: Props[]
  fetch: (list: Props[]) => void
  stop: () => void
}

export function useSendAllFetch<
  Action = DefaultFetch | FetchCreate | FetchGet | FetchUpdate | FetchDelete,
  Props = AnyObject
>(
  action: Action & (DefaultFetch | FetchCreate | FetchGet | FetchUpdate | FetchDelete),
  initialList?: Props[],
): SendAllFetch<Props> {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError<DefaultFetchError> | null>(null);
  const copyList = useRef<Props[]>(initialList === undefined ? [] : [...initialList].reverse());

  useEffect(() => {
    if (action.response && !action.error) {
      if (copyList.current.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action.fetch.apply(null, copyList.current.pop() as any);
      } else {
        setLoading(false);
      }
    }
  }, [action.response]);

  useEffect(() => {
    if (action.error) {
      setLoading(false);
      setError(action.error);
    }
  }, [action.error]);

  return {
    loading,
    error,
    list: copyList.current,
    fetch: (list?: Props[]) => {
      if (typeof list !== 'undefined') {
        copyList.current = [...list].reverse();
      }
      if (copyList.current.length > 0) {
        setLoading(true);
        setError(null);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action.fetch.apply(null, copyList.current.pop() as any);
      }
    },
    stop: () => {
      copyList.current = [];
      setLoading(false);
    },
  };
}
