import React, { useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { ItemType } from 'antd/es/menu/interface';
import { DownloadIcon } from '../../Icon';
import { humanFileSize } from '../../../../utils';
import { AssetByIdGet, AssetGet, getFileSize } from '../../../../hooks/api/asset';
import { ListSize } from '../../../Pages/Share/SelectedList';
import { AnyObject } from '../../../../types';

import styles from './index.module.scss';

export interface DownloadButtonProps extends AnyObject {
  className?: string;
  asset?: AssetByIdGet | AssetGet;
  onClick: (type: ListSize) => void;
}

export default function DownloadButton({
  asset,
  className = '',
  onClick,
  ...props
}: DownloadButtonProps): React.ReactNode {
  const { t } = useTranslation();
  const [fakeLoading, setFakeLoading] = useState(false);

  useEffect(() => {
    if (fakeLoading) {
      setTimeout(() => setFakeLoading(false), Math.max(Math.min(Math.trunc(getFileSize(asset) / 20), 9000), 500));
    }
  }, [fakeLoading]);

  return (
    <Dropdown.Button
      className={className}
      size="large"
      icon={<DownloadIcon />}
      loading={fakeLoading}
      type="primary"
      menu={{
        items: (asset ? [
          asset?.urlPreview ? 'preview' : null,
          asset?.urlExport ? 'export' : null,
          asset?.urlMaster ? 'master' : null,
        ]
          : ['preview', 'export', 'master'])
          .filter((name) => name).map((name) => ({
            key: name,
            label: asset ? (
              <div className={styles.spaceBetween}>
                <span>{t(name as string)}</span>
                <span>{humanFileSize(getFileSize(asset, name as ListSize))}</span>
              </div>
            ) : t(name as string),
          })) as ItemType[],
        onClick: (e) => {
          setFakeLoading(true);
          onClick(e.key as ListSize);
        },
      }}
      onClick={(e) => {
        e.preventDefault();
        setFakeLoading(true);
        onClick('export');
      }}
      {...props}
    >
      {t('download')}
      {asset ? (
        ` ${humanFileSize(getFileSize(asset))}`
      ) : null}
    </Dropdown.Button>
  );
}
